// 页面固定高度滚动
.page-card {
  height: calc(100% - 128px);
  overflow-y: auto;
}

// 中号抽屉
.middle-drawer {
  .MuiDrawer-paper.MuiPaper-root {
    width: 900px;
  }
}

// 大号抽屉
.large-drawer {
  .MuiDrawer-paper.MuiPaper-root {
  width: calc(100% - 400px);
    min-width: 1200px;
  }
}

// 抽屉对应-消息条提示
.SnackbarContainer-right, .MuiSnackbar-anchorOriginTopRight {
  right: 306px !important;
}
