@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes fadeInFromBelow {
  0% {
    opacity: 0;
    transform: translateY(30px)
  }

  to {
    opacity: 1;
    transform: none
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-30px)
  }

  to {
    opacity: 1;
    transform: none
  }
}

@keyframes fade {
  from {
    opacity: 1.0;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1.0;
  }
}

@keyframes move {
  0% {
    offset-distance: 0;
  }
  100% {
    offset-distance: 100%;
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    -ms-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3)
  }
  50% {
    opacity: 1
  }
}

@keyframes starFlash {
  0% {
    filter: brightness(0.3);
  }
  50% {
    filter: brightness(1);
  }
  100% {
    filter: brightness(0.3);
  }
}

@keyframes threeFlash {
  0% {
    opacity: 0
  }
  25% {
    opacity: 1
  }
  50% {
    opacity: 0
  }
  65% {
    opacity: 1
  }
  80% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

@keyframes moveCenter {
  0% {
    offset-distance: 0;
  }
  5% {
    offset-distance: 0;
  }
  20% {
    offset-distance: 25%;
  }
  40% {
    offset-distance: 50%;
  }
  60% {
    offset-distance: 50%;
  }
  80% {
    offset-distance: 75%;
  }
  95% {
    offset-distance: 100%;
  }
  100% {
    offset-distance: 100%;
  }
}

@keyframes seamlessScrolling {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, -50%, 0);
  }

  //0% {
  //  transform: translateY(0px);
  //}
  //100% {
  //  transform: translateY(-50%);
  //}
}

@keyframes slideInWidth {
  0% {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes bounceInDown {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0) scaleY(3)
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0) scaleY(.9)
  }
  75% {
    transform: translate3d(0, -10px, 0) scaleY(.95)
  }
  90% {
    transform: translate3d(0, 5px, 0) scaleY(.985)
  }
  to {
    transform: translateZ(0)
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)
  }
  to {
    opacity: 1;
    transform: translateZ(0)
  }
}

