// 宽高百分比
.wh-100 {
  width: 100%;
  height: 100%;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

// flex布局
.flex {
  flex: 1;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-bottom {
  display: flex;
  // justify-content: start;
  align-items: flex-end;
}

.flex-top {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}


.p-r {
  position: relative;
}

.p-a {
  position: absolute;
}


.va-middle {
  display: inline-block;
  vertical-align: middle;
}

.pointer {
  cursor: pointer;

  &:hover {
    color: #90caf9
  }
}

.disable {
  cursor: no-drop;
  color: #8c8c8c;
}

.overflow-auto {
  overflow: auto;
}

.margin-auto {
  margin: 0 auto;
}

.inline-block {
  display: inline-block;
}

.ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.ellipsis-span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}
