* {
  vertical-align: baseline;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

// 隐藏默认浏览器滚动条
::-webkit-scrollbar-track-piece {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background-color: #dddddd;
  background-clip: padding-box;
  min-height: 28px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}


// 自定义字体
@font-face {
  font-family: Teko;
  src: url('../assets/fonts/teko.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}